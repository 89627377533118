import { useLocation, useLoaderData } from "@remix-run/react";
import { redirectDocument } from "@remix-run/node";
import { GeneralErrorBoundary } from "~/components/error-boundary";
import { FourOhFour } from "~/components/errors";
import { getPage } from "~/models/pages.server";
import { json } from "@remix-run/node";
import { CACHE_CONTROL } from "~/models/http.server";
import invariant from "tiny-invariant";

export const loader = async ({ params, request }) => {
  let { slug } = params;

  let requestUrl = new URL(request.url);
  let siteUrl = requestUrl.protocol + "//" + requestUrl.host;
  let page = await getPage(slug);

  // throw new Response("Not found", { status: 404 });

  return json(
    { siteUrl, page },
    { headers: { "Cache-Control": CACHE_CONTROL.DEFAULT } },
  );
};

export default function PageContet() {
  let { page } = useLoaderData();

  return (
    <div className="">
      {page.draft ? (
        <div className="m-auto mb-8 max-w-4xl rounded-sm bg-red-700 px-5 py-3 text-center text-gray-100">
          🚨 This is a draft, please do not share this page until it's
          officially published 🚨
        </div>
      ) : null}

      <div className="bg-indigo-200">
        <div className="text-h2 container mx-auto flex max-w-5xl flex-col items-center justify-center gap-y-4 py-8 lg:px-44 lg:py-20">
          {/* <div>
            <BookOpen size={62} className="text-orange-800" />
          </div> */}
          <h1 className="text-pretty text-center text-3xl font-bold text-slate-950 lg:text-5xl/tight">
            {page.title}
          </h1>
          <div className="flex flex-col gap-y-1 text-center">
            <div className="text-sm">Andrew Lynch</div>

            <div className="flex gap-2 text-sm">
              <div>{page.dateDisplay}</div>
              <span className="text-gray-500">•</span>
              <div> {page.readingTime.text}</div>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex flex-col items-center ">
        <div className="w-full max-w-3xl px-5 py-8">
          <article
            // ref={mdRef}
            className="prose prose-slate lg:prose-lg md:mx-4"
            dangerouslySetInnerHTML={{ __html: page.html }}
          />
          {/* <Newsletter /> */}
        </div>
      </div>
    </div>
  );
}

export const meta = (args) => {
  let { data, params } = args;
  let { slug } = params;
  invariant(!!slug, "Expected slug param");

  let { siteUrl, page } = data || {};

  if (!page) {
    return [{ title: "404 Not Found | Shopdeploy" }];
  }

  // const { src, srcSet } = useResponsiveImage(post.imageUrl, responsive);
  // let ogImageUrl = siteUrl ? new URL(`${siteUrl}${post.imageUrl}`) : null;
  // let socialImageUrl = ogImageUrl?.toString();
  let url = siteUrl ? `${siteUrl}/${slug}` : null;

  return [
    { title: page.title + " | Shopdeploy" },
    { name: "description", content: page.summary },
    { property: "og:url", content: url },
    { property: "og:title", content: page.title },
    // { property: "og:image", content: socialImageUrl },
    // { property: "og:image:alt", content: post.imageAlt },
    // { property: "og:image:width", content: 1200 },
    // { property: "og:image:height", content: 600 },
    { property: "og:description", content: page.summary },
    { property: "og:type", content: "article" },
    { property: "article:published_time", content: page.dateDisplay },
    {
      property: "article:publisher",
      content: "https://www.facebook.com/shopdeploy",
    },

    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:creator", content: "@shopdeploy" },
    { name: "twitter:site", content: "@shopdeploy" },
    { name: "twitter:title", content: page.title },
    { name: "twitter:description", content: page.summary },
    // { name: "twitter:image", content: socialImageUrl },
    // {
    //   name: "twitter:image:alt",
    //   content: socialImageUrl ? post.imageAlt : undefined,
    // },
    // {
    //   tagName: "link",
    //   rel: "preload",
    //   href: src,
    //   imageSrcSet: srcSet,
    //   imageSizes: sizes,
    //   as: "image",
    // },
  ];
};

export function ErrorBoundary() {
  const location = useLocation();
  return (
    <GeneralErrorBoundary
      statusHandlers={{
        404: () => <FourOhFour />,
        500: () => <div>Server error</div>,
      }}
    />
  );
}
